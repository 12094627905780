<template>
    <v-layout column class="pa-6" v-on:keyup.enter="login">
        <v-flex sm12>
            <h3>Login:</h3>
        </v-flex>

        <v-alert v-if="!!notification_message"
                :value="true"
                :type="notification_type"
                :dense="true"
        >{{notification_message}}</v-alert>

        <v-flex>
            <v-form @submit="login">
                <v-text-field prepend-icon="mdi-account"
                              v-model="username"
                              name="username"
                              autocomplete="ws-username"
                              :rules="username_rules"
                              label="Username" type="text">
                </v-text-field>
                <v-text-field id="password"
                              v-model="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              autocomplete="ws-password"
                              :rules="password_rules"
                              label="Password"
                              type="password">
                </v-text-field>
            </v-form>
        </v-flex>
        <v-flex>
            <router-link :to="{name: 'Register'}">
                <span>Forgot password?</span>
            </router-link>
        </v-flex>

        <v-flex>
            <router-link :to="{name: 'ResetPassword'}">
                <span>Register</span>
            </router-link>
        </v-flex>

        <v-flex>
            <v-btn v-on:click="login"  v-on:submit="login" :disabled="!valid">Login</v-btn>
        </v-flex>

    </v-layout>

</template>

<script>
    export default {

        data() {
            //TODO: Fix validation bbefore a user can login
            return {
                notification: false,
                notification_message: '',
                notification_type: 'error',
                username: "",
                password: "",
                valid: true,
                username_min: 4,
                password_min: 6,
                allowSpaces: false,
            }
        },
        computed: {
            username_rules() {
                const username_rules = [];
                if (this.username_min) {
                    const rule =
                        v => (v || '').length >= this.username_min ||
                            `A minimum of ${this.username_min} characters is required`;
                    username_rules.push(rule)
                }
                if (!this.allowSpaces) {
                    const rule =
                        v => (v || '').indexOf(' ') < 0 ||
                            'No spaces are allowed';

                    username_rules.push(rule)
                }
            },
            password_rules() {
                let password_rules = [];
                if (this.username_min) {
                    let rule =
                        v => (v || '').length >= this.password_min ||
                            `A minimum of ${this.password_min} characters is required`;
                    password_rules.push(rule)
                }
                if (!this.allowSpaces) {
                    let rule =
                        v => (v || '').indexOf(' ') < 0 ||
                            'No spaces are allowed';

                    password_rules.push(rule)
                }
            },

        },
        methods: {
            login: function () {
                let username = this.username;
                let password = this.password;

                this.$store.dispatch('login', {username, password})
                    .then((r) => {
                        if(r.data.token === '')
                        {
                            console.log("ERROR");
                            this.notification = true;
                            this.notification_message = 'Error: User account disabled. Please contact administrator';
                            this.notification_type = 'error';
                        } else {
                            this.notification_message = 'Successful Login, please wait...';
                            this.notification_type = 'success';
                            // this.$router.push('/c-panel');
                            location.reload();
                        }
                    })
                    .catch((error) => {
                        this.notification_message = 'Error password or username incorrect, please try again';
                        this.notification_type = 'error';
                        console.log(this.notification_message);
                    });
                return false;
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>
